import React from 'react'
import { Link } from "gatsby"

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Calendly from '../components/Calendly'

import system from "../../content/assets/system.png"
import styles from "./index.module.styl"

const title = 'FELD Berlin. A data science company'
const description = `
  FELD’s network of experienced data scientists and engineers can
  help you to improve your data practice and can plan and implement your
  data science projects. If you want to build better foundations for your
  current projects or want to tackle custom problems with cutting edge
  methods, hire us. We will deploy effective and ethical solutions that
  work for you.
`

class Home extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout location={ this.props.location }>
        <div className={ styles.index }>
          <SEO title={ title } description={ description } />
          <div className={ styles.claim }>
            <h1>Hire a great research engineer</h1>
          </div>
          <div className={ styles.main }>
            <p>{ description }</p>
            <h2>Research Engineering</h2>
            <p>
              We can make custom machine learning and data science
              solutions work for your business. Early in the project we can
              assess the viability of your plans and help to steer you into the right
              direction. We can then implement and iterate on the problem with structured
              experiments. Hire us to implement recommender systems, to test and
              improve your search system performance, or
              to build bespoke ML solutions. Learn more about how we work, in <Link to="/services/research-engineering">data science</Link>
            </p>
            <h2>Data Foundations</h2>
            <p>
              Your data science, machine learning and analytics efforts all
              rely on the ability to produce and consume timely, accurate
              datasets in a consistent self service way. Make this vision real
              on AWS, GCP or Azure together with our architects. Learn more in <Link to="/services/data-foundations">data foundations</Link>
            </p>
          </div>
          <div className={ styles.howWeWork }>
            <img src={system} alt="Coordinate System" />
            <p>Get your data to do more</p>
            <h3>1. Assess</h3>
            <p>
              Get a fresh look at your data organisation and your problem. Our
              architects will review where you’re at, and then help you to plan an
              effective strategy for your project.
            </p>
            <h3>2. Prototype</h3>
            <p>
              Get first results immediately. FELD will produce actionable
              insights in the form of notebooks, working prototypes and
              presentations of key results.
            </p>
            <h3>3. Release</h3>
            <p>
              Running data products requires careful monitoring of metrics,
              effective use of A/B testing and continual validation of the system.
            </p>
          </div>
          <div className={ styles.footer }>
            <blockquote>
              Our new data platform has quickly become
              indispensable to us at BSDEX.
            </blockquote>
            <div className={ styles.attribution }>
              Peter Grosskopf, MD and CTO of Börse Stuttgart Digital Exchange
            </div>
            <div className={ styles.questions }>
              Drop us a line for a chat <br />
              in the free FELD office hour
            </div>
            <Calendly text="Schedule a call"/>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Home
